// import { storeToRefs } from 'pinia'
import { useToast } from '~/modules/ui/components/toast'

export async function useRefreshOrganizationData() {
  const { organization, isLoaded } = useOrganization()

  if (!isLoaded.value) {
    useToast().toast({
      title: 'Organization not loaded',
      description: 'Clerk organization data not loaded',
    })
    return
  }

  // We call getMemberships() to force a refresh of the organization data
  const memberships = await organization.value?.getMemberships()
  useLogger().log('Organization data updated:', {
    organization: organization.value,
    memberships,
  })
}

export function useActiveOrgComputed() {
  const { organization } = useOrganization()

  // Using computed to maintain reactivity while accessing nested properties
  return computed(() => organization.value)
}

export function useActiveOrgIdRef() {
  const { organization } = useOrganization()

  // Create a ref to store the organization ID
  const orgIdRef = ref(organization.value?.publicMetadata?.soil_link_org_id)

  // Watch for changes in the organization and update the ref accordingly
  watch(() => organization.value?.publicMetadata?.soil_link_org_id, (newId) => {
    orgIdRef.value = newId
  })

  return orgIdRef
}

export function useActiveOrgName() {
  const { organization } = useOrganization()

  return computed(() => organization.value?.name)
}
